import "../styles/signup-form.scss";
import jsonp from "jsonp";
import React from "react";
import LoadingIndicator from "./loading-indicator"


class SignupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      success: null,
    }

    const baseURL = new URL("https://saarasantala.us20.list-manage.com/subscribe/");
    const urlParams = new URLSearchParams("u=f99058d174cc4817e407a0d43&id=94b9c61cb7");
    this.formURL = `${baseURL.toString()}post?${urlParams.toString()}`;
    this.jsonURL = `${baseURL.toString()}post-json?${urlParams.toString()}&c=?`;

  }

  submit = (e) => {
    // First, check form validity
    // If not valid, continue submission > MailChimp page opens
    // If valid, try to submit via AJAX
    // If AJAX submission is successful and subscription succeeds > Show success message
    // If AJAX submission is successful, but subscription fails > Direct to MailChimp
    // If AJAX submission is unsuccessful > Show fail message

    const form = e.target;

    if (!form) { return; } // Early exit

    let isFormValid = true;
    const inputs = form.elements;
    for (let i = 0; i < inputs.length; i++) {
      isFormValid = isFormValid && inputs.item(i).reportValidity();
    }
    if (!isFormValid) {
      // The form is invalid > submit form with default settings
      // This will open the MailChimp subscription form
      return;
    } else {
      // Submit via AJAX
      e.preventDefault(); // Cancel submission event

      // Prepare as a GET request
      const url = new URL(this.jsonURL);
      for (const pair of new FormData(form)) {
        url.searchParams.append(pair[0], pair[1]);
      }
      // Cross origin with jsonp
      jsonp(url.toString(), { param: "c" }, (err, data) => {
        if (!!err) {
          // Request failed
          this.setState({ success: false });
        } else if (data.result === "error") {
          // Error from MailChimp > submit manually to open their feedback page
          form.submit();
        } else {
          // Successfully subscribed
          this.setState({ success: true });
        }
      });
      this.setState({ submitted: true, success: null });
    }
  };

  clearSubmitState = () => {
    this.setState({ submitted: false });
  }

  render() {
    const { submitted, success } = this.state;

    return <section className="signup-form">
      <form action={this.formURL} method="post" onSubmit={this.submit} noValidate>
        <h1>Tilaa uutiskirje</h1>
        <p>
          Mistä brändin rakentamisessa on kyse? Miksi panostaisin brändiin, kun tarvitsen vain nettisivut?
          Ja miksi workshop on välttämätön? Näihin ja moniin muihin kysymyksiin vastaan uutiskirjeessäni?
        </p>
        <input type="text" name="NAME" placeholder="Nimi" aria-label="Nimi" required
               onChange={this.clearSubmitState} />
        <input type="email" name="EMAIL" placeholder="Sähköpostiosoite" aria-label="Sähköpostiosoite" required
               onChange={this.clearSubmitState} />
        <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
          <input type="text" name="b_f99058d174cc4817e407a0d43_94b9c61cb7" tabIndex="-1" />
        </div>
        <button type="submit" name="subscribe" className={submitted && success === null ? "loading" : ""}>
          {submitted
            ? (success === true ? "Kiitos tilauksestasi" : (success === false ? "Tilaus epäonnistui" : <><LoadingIndicator light />Lähetetään…</>))
            : "Tilaa uutiskirje"
          }
        </button>
      </form>
    </section>
  }
}

export default SignupForm;
