import "../styles/category.scss";

import React from "react";

import { Link } from "gatsby";
import Layout from "./layout"
import SEO from "./seo";
import Background from "./background"

class Category extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      carouselActivePost: null
    };

  }

  stopCarousel = (itemId) => {
    this.setState({ carouselActivePost: itemId })
  };

  resumeCarousel = () => {
    this.setState({ carouselActivePost: null })
  };

  render() {
    const { carouselActivePost } = this.state;
    const { category = {}, siteData } = this.props;
    const {
      postsInCategories = new Map(),
    } = siteData;
    const { position = "left" } = category.acf;
    const posts = postsInCategories.get(category.id) || [];
    const carouselItems = (posts).map(({ id, title, link, acf = {} }) => ({
      id,
      title: title.rendered || "",
      link,
      image: acf["carousel-image"] || "",
    }));

    return <Layout className="category" siteData={siteData} position={position}>
      <SEO title={category.name} />
      <div className={`category-posts`}>
        <ul>
          {posts.map((post, i) => (
            <li key={post.id}>
              <Link className="post-link" to={new URL(post.link).pathname}
                    onMouseEnter={e => this.stopCarousel(post.id)}
                    onMouseOut={this.resumeCarousel} onBlur={this.resumeCarousel}>
                <span>{(post.title || {}).rendered || ""}</span>
                {(!!post.acf && !!post.acf["carousel-image"])
                  ? <img className="post-image" src={post.acf["carousel-image"]} alt="" />
                  : ""}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <Background item={category} carouselItems={carouselItems} activeCarouselItemId={carouselActivePost} siteData={siteData} />
    </Layout>
  }
}

export default Category
