import React from "react";

import "../styles/footer.scss";
import Carousel from "./carousel"
import SignupForm from "./signup-form"

const Background = ({ item = { acf: {} }, siteData, carouselItems = [], activeCarouselItemId }) => {

  const {
    "background-image": backgroundImage = "",
    "show-carousel": showCarousel = false,
    "show-signup-form": showSignupForm = false,
  } = item.acf;

  return <>
    {showSignupForm ? <SignupForm /> : ""}
    {!showCarousel && !!backgroundImage
      ? <div className="background" style={{ backgroundImage: "url("+backgroundImage+")" }}></div>
      : "" }
    {!!showCarousel && !!carouselItems.length
      ? <Carousel items={carouselItems} activeItemId={activeCarouselItemId} />
      : ""}
  </>
}

export default Background;