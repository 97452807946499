import "../styles/layout.scss"

import React from "react"

import Header from "./header"

import Footer from "./footer"

const Layout = ({ className = "", position = "", siteData, children }) => {

  return (
    <div id="layout" className={`${className} ${position ? "position-" + position : ""}`}>
      <Header siteData={siteData} />
      <main>{children}</main>
      <Footer siteData={siteData} />
    </div>
  )
}

export default Layout
