import { Link } from "gatsby"
import React from "react"

import "../styles/header.scss";

const Header = ({ siteData = {} }) => {
  const { menuItems = [] } = siteData;

  return <header id="site-header">
    <h1><Link to="/">Studio<br/>Saara<br/>Santala</Link></h1>
    {!!menuItems
      ? <nav className="main">
        <ul>
          {menuItems.map(item => (
            <li key={item.slug}>
              <Link activeClassName="current-page" to={new URL(item.url).pathname}>{item.title}</Link>
            </li>
          ))}
        </ul>
      </nav>
      : ""
    }
  </header>
}

export default Header;