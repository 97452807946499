import React from "react";

import "../styles/footer.scss";

const encode = (str) => str.replace(/[\u00A0-\u9999<>&]/gim, (i) => '&#'+i.charCodeAt(0)+';');

class ContactLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      href: false,
    };
  }

  reveal = () => {
    const { send = false, call = false, address } = this.props;
    const protocol = (send !== false
        ? "mailto:"
        : (call !== false ? "tel:" : "")
    );
    this.setState({ href: protocol + address });
  }

  render() {
    const { address } = this.props;

    if (!this.state.href) {
      return <a className="link rev" href="/" onFocus={this.reveal} onMouseEnter={this.reveal} onPointerEnter={this.reveal}>
        {address.split('').reverse().map((c, i) => (
          c === "@" ? <span key={i} className="at"></span> : <span key={i}>{c}</span>
        ))}
      </a>
    } else {
      return <a href={this.state.href}>{encode(address)}</a>
    }
  }
}

const Footer = ({ siteData = {} }) => {
  const { socialMediaLinks = new Map() } = siteData;

  const addr = `hello${String.fromCharCode(4+60)}saarasantala.fi`;
  const num = "+" + [358, 50, 3414, 788].join(" ");

  return <footer id="site-footer">
    <ContactLink send address={addr}/>
    <br/>
    <ContactLink call address={num}/>
    <br/>
    <br/>
    {["Instagram", "Facebook", "LinkedIn"].map(site => {
      const key = site.toLowerCase();
      return (socialMediaLinks.has(key)
          ? <a key={key} href={socialMediaLinks.get(key)} title={site}>{site}</a>
          : "")
    }).reduce((acc, e) => acc === null ? e : <>{acc}&nbsp;|&nbsp;{e}</>, null)}
  </footer>
}

export default Footer;