import "../styles/loading.scss";

import React from "react";
import LoadingIndicator from "./loading-indicator"

const Loading = ({ loadingFailed = false }) => (
  <div id="loading">
    <p className="status">
      <LoadingIndicator />
      <span className="indicator" ><span className="inner"></span></span>
      {loadingFailed ? "Sivun lataus epäonnistui." : "Ladataan…"}
    </p>
  </div>
);

export default Loading;
