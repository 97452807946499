import "../styles/post.scss";

import sanitizeHtml from "sanitize-html"
import React from "react";

import SEO from "./seo";
import Pagination from "./pagination";

import Layout from "./layout"


const Post = ({ post = { acf: {}, title: {}, content: {} }, siteData }) => {

  const {
    categoriesOfPosts = new Map(),
    postsInCategories = new Map(),
  } = siteData;

  const {
    "header-image": headerImg = "",
  } = post.acf;

  const title = post.title.rendered || "";
  const content = post.content.rendered || "";
  const excerpt = sanitizeHtml(post.excerpt.rendered || "", { allowedTags: [] });

  const otherPosts = categoriesOfPosts.get(post.id).map(c => postsInCategories.get(c.id) || []).flat();
  let prev = false, next = false, temp = false;
  otherPosts.forEach(p => {
    if (p.id === post.id) {
      prev = temp;
    } else if (temp.id === post.id) {
      next = p;
    }
    temp = p;
  });

  return <Layout className="post" siteData={siteData}>
    <SEO title={title} description={excerpt} />
    <article className="post">
      <header>
        <h1>{title}</h1>
        {!!headerImg
          ? <figure className="featured">
            <img src={headerImg} alt="" />
          </figure>
          : "" }
      </header>
      <Pagination prev={prev} next={next} back={categoriesOfPosts.get(post.id)[0]} />
      <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
    </article>
  </Layout>

}

export default Post
