import React from "react";
import { Link } from "gatsby";

import "../styles/pagination.scss";

const Pagination = ({ prev = false, next = false, back = false }) => (
  <nav className="pagination">
    {!!prev ? <Link to={new URL(prev.link).pathname}>Edellinen</Link> : <span>Edellinen</span>}
    <Link to={new URL(back.link).pathname}>Takaisin</Link>
    {!!next ? <Link to={new URL(next.link).pathname} >Seuraava</Link> : <span>Seuraava</span>}
  </nav>
);

export default Pagination;
