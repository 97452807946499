import "../styles/page.scss";

import sanitizeHtml from "sanitize-html";
import React from "react";

import SEO from "./seo";

import Layout from "./layout"
import Background from "./background"

const Page = ({ page = { acf: {}, title: {}, content: {} }, siteData }) => {

  const { postsInCategories = new Map() } = siteData;
  const {
    "carousel-category": carouselCategory = {},
    "use-links-in-carousel": useLinksInCarousel = true,
    "hide-content-on-mobile": hideContentOnMobile = false,
    position = "left",
  } = page.acf;
  const title = page.title.rendered || "";
  const content = page.content.rendered || "";
  const excerpt = sanitizeHtml(page.excerpt.rendered || "", { allowedTags: [] });
  const carouselItems = (postsInCategories.get(carouselCategory["term_id"]) || []).map(({ id, title, link, acf = {} }) => ({
    id,
    title: title.rendered || "",
    link: useLinksInCarousel ? link : false,
    image: acf["carousel-image"] || "",
  }));

  return <Layout className="page" siteData={siteData} position={position}>
    <SEO title={title} description={excerpt} />
    <article className={`page ${hideContentOnMobile ? "hide-content-on-mobile" : ""}`}>
      <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
      <Background item={page} carouselItems={carouselItems} siteData={siteData} />
    </article>
  </Layout>

};

export default Page
