import "../styles/carousel.scss";

import React from "react";

import { Link } from "gatsby";

class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItemIdIdx: 0,
      interval: null,
    };
  }

  updateCarousel = (i) => {
    this.setState({ activeItemIdIdx: i });
    this.stopCarousel();
  };

  incrementCarousel = () => {
    const { items = [] } = this.props;
    this.setState({ activeItemIdIdx: (this.state.activeItemIdIdx + 1) % items.length });
  }

  startCarousel = () => {
    if (this.state.interval === null) {
      this.setState({ interval: window.setInterval(this.incrementCarousel, 5000) });
    }
  }

  stopCarousel = () => {
    window.clearInterval(this.state.interval);
    this.setState({ interval: null });
  }

  componentDidMount() {
    this.startCarousel();
  }

  componentWillUnmount() {
    this.stopCarousel();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const currProps = this.props;
    if (currProps.activeItemId !== prevProps.activeItemId) {
      if (!!currProps.activeItemId) {
        this.stopCarousel();
        const activeItemIdIdx = currProps.items.findIndex(item => item.id === currProps.activeItemId);
        this.setState({ activeItemIdIdx });
      } else {
        this.startCarousel();
      }
    }
  }

  render() {
    const { items = [] } = this.props;

    return <div className={`carousel ${this.state.interval === null ? "stopped" : "started"}`}>
      {items.map((item, i) => {
        const className = "carousel-item " + (this.state.activeItemIdIdx === i ? "active-item" : "");
        const style = { backgroundImage: `url("${item.image || ""}")` };
        const mouseEnterFunc = () => this.updateCarousel(i);
        const mouseOutFunc = () => this.startCarousel();
        return (!!item.link
          ? <Link key={item.id} to={!!item.link ? new URL(item.link).pathname : ""} title={item.title || ""}
                  className={className} onMouseEnter={mouseEnterFunc} onMouseOut={mouseOutFunc} onBlur={mouseOutFunc}
                  style={style} />
          : <div key={item.id} className={className} role="img"
                 onMouseEnter={mouseEnterFunc} onMouseOut={mouseOutFunc} onBlur={mouseOutFunc} style={style} />)
      })}
    </div>
  }
}

export default Carousel
