import React from "react";
import { Router, globalHistory } from "@reach/router";
import WPAPI from 'wpapi';
import ReactGA from 'react-ga';

import NotFound from "../components/404"
import Loading from "../components/loading"
import Category from "../components/category"
import Page from "../components/page";
import Post from "../components/post";


const wp = new WPAPI({ endpoint: 'https://wp.saarasantala.fi/wp-json' });
wp.menus = wp.registerRoute( "menus/v1", "/menus/(?P<id>[a-zA-Z0-9_-]+)");

ReactGA.initialize('UA-132352945-1');

globalHistory.listen(() => {
  console.log("GA", window.location.pathname);
  ReactGA.pageview(window.location.pathname + window.location.search);
});

const loadSiteData = async () => {
  const pages = await wp.pages().get();
  const socialMediaLinks = pages.reduce((socialMediaLinks, page) => {
    ["instagram", "facebook", "linkedin"].forEach(site => {
      socialMediaLinks.set(site, socialMediaLinks.get(site) || (page.acf || {})[site]);
    });
    return socialMediaLinks;
  }, new Map());

  const categories = await wp.categories().get();
  const postsInCategories = new Map();
  const categoriesOfPosts = new Map();
  await Promise.all(categories.map(async category => {
    const posts = await wp.posts().categories(category.id).get();
    postsInCategories.set(category.id, posts);
    posts.forEach(post => {
      const categories = categoriesOfPosts.get(post.id) || [];
      categories.push(category);
      categoriesOfPosts.set(post.id, categories);
    });
  }));

  const mainMenu = await wp.menus().id("main").get();
  const menuItems = mainMenu.items.sort((a, b) => a.menu_order - b.menu_order);

  return {
    pages,
    categories,
    postsInCategories,
    menuItems,
    categoriesOfPosts,
    socialMediaLinks,
  };
};


class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      siteData: null,
      loadingFailed: false,
    }

  }

  componentDidMount() {
    loadSiteData()
      .then(siteData => {
        this.setState({ siteData });
      })
      .catch(e  => {
        console.log("Failed to load data", e);
        this.setState({ loadingFailed: true });
      });
  }

  render() {
    const {
      siteData = null,
      loadingFailed = false,
    } = this.state;

    const dataLoaded = !!siteData;
    const { categories = [], pages = [], postsInCategories = new Map() } = siteData || {};

    return <Router>
      {!dataLoaded || true
        ? <Loading default loadingFailed={loadingFailed} />
        : <NotFound default siteData={siteData} />
      }
      {pages.map(page => (
        <Page key={page.id} path={new URL(page.link).pathname} page={page} siteData={siteData} />
      ))}
      {categories.map(category => (
        <Category key={category.id} path={new URL(category.link).pathname}
                  category={category} siteData={siteData} />
      ))}
      {[...postsInCategories.values()].flat().map(post => (
        <Post key={post.id} path={new URL(post.link).pathname}
              post={post} siteData={siteData} />
      ))}
    </Router>
  }
}

export default Index
